<template>
    <page-content title="三点校正">


        <a-row :gutter="[16,16]">

            <a-col>
                <div class="hint">
                    请先点击中间蓝色按钮
                    <div class="bold">启动校准</div>
                    ，然后再点击要修改的值，修改完成后，点击中间
                    <div class="bold">完成校准</div>
                    。
                </div>
            </a-col>

            <a-col :span="12" v-for="item in items">
                <AdjustItem class="adjust-item" @control="control"
                            :model="item" :valueMap="valueMap"></AdjustItem>
            </a-col>
        </a-row>

    </page-content>
</template>

<script>
    import BaseDeviceView from "../BaseDeviceView";
    import AdjustItem from "./AdjustItem";
    export default {
        extends: BaseDeviceView,
        components:{AdjustItem},
        data(){
            return {
                items:[
                    {
                        name:"ORP",
                        current:"reg4",
                        start:"reg56",
                        read:["reg23","reg24","reg25","reg26","reg27","reg28","reg29"],
                        rate: 1
                    },
                    {
                        name:"温度",
                        current:"reg3",
                        start:"reg54",
                        read:["reg9","reg10","reg11","reg12","reg13","reg14","reg15"],
                        rate: 10
                    },
                    {
                        name:"PH",
                        current:"reg5",
                        start:"reg57",
                        read:["reg30","reg31","reg32","reg33","reg34","reg35","reg36"],
                        rate: 100

                    },
                    {
                        name:"余氯",
                        current:'reg6',
                        start:"reg55",
                        read:["reg16","reg17","reg18","reg19","reg20","reg21","reg22"],
                        rate: 100
                    },
                    {
                        name:"浊度",
                        current:'reg7',
                        start:"reg44",
                        read:["reg38","reg39","reg40","reg41","reg42","reg43","reg37"],
                        rate: 100
                    }
                ]
            }
        },
        methods:{

        }
    }
</script>

<style lang="less" scoped>
.hint{
    display: flex;
    align-items: center;
    background: #F8F8F8;
    padding: 8px 20px;
    border-left: 4px solid #F44;
    font-size: 16px;

    .bold{
        font-weight: 600;
        padding:2px 10px;
        color: #0084FF;
    }
}


</style>
