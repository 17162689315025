<template>
    <div class="adjust-item">

        <div class="title">{{model.name}}探头校准</div>

        <template v-for="(item,index) in array">
            <div class="grid" v-bind:class="{'grid-disable':!isAdjusting}">
                <a-button class="btn" :disabled="item.disable" @click="clickItem(item,index)">{{item.value}}</a-button>
                <div class="value">{{item.name}}</div>
            </div>
            <div class="grid" v-if="index==3">
                <a-button v-if="!isAdjusting" class="btn" type="primary" @click="clickStart">启动校准</a-button>
                <a-button v-else class="btn" type="danger" @click="clickFinish">完成校准</a-button>
                <div class="status-value">{{isAdjusting?"校准中...":"请先启动校准"}}</div>
            </div>
        </template>

        <InputDialog ref="inputDialog"></InputDialog>

    </div>
</template>

<script>
    export default {
        props:["model","valueMap"],
        computed:{
            isAdjusting(){
                return parseInt(this.valueMap[this.model.start])>0
            },
            array(){
                return [
                    {
                        name:"第一点采样值",
                        value:this.valueMap[this.model.read[0]] / this.getRate(0),
                        identifier:this.model.read[0],
                        disable:false},
                    {
                        name:`当前${this.model.name}值`,
                        value:this.valueMap[this.model.current],
                        disable:true},
                    {
                        name:"第一点校准值",
                        value:this.valueMap[this.model.read[1]] / this.getRate(2),
                        identifier:this.model.read[1],
                        disable:false},
                    {
                        name:"第二点采样值",
                        value:this.valueMap[this.model.read[2]] / this.getRate(3),
                        identifier:this.model.read[2],
                        disable:false},
                    {
                        name:"第二点校准值",
                        value:this.valueMap[this.model.read[3]] / this.getRate(4),
                        identifier:this.model.read[3],
                        disable:false},
                    {
                        name:"第三点采样值",
                        value:this.valueMap[this.model.read[4]] / this.getRate(5),
                        identifier:this.model.read[4],
                        disable:false},
                    {
                        name:"三点采样值",
                        value:this.valueMap[this.model.read[6]] / this.getRate(6),
                        disable:true},
                    {
                        name:"第三点校准值",
                        value:this.valueMap[this.model.read[5]] / this.getRate(7),
                        identifier:this.model.read[5],
                        disable:false},
                ]
            }
        },
        methods:{
            clickItem(model,index){
                let self = this
                this.$refs.inputDialog.show({
                    title:model.name,
                    value:model.value,
                    onOk(value){
                        self.control(model.identifier,value*self.getRate(index))
                    }
                })
            },
            clickStart(){
                this.control(this.model.start,1)
            },
            clickFinish(){
                this.control(this.model.start,0)
            },
            control(identifier,value){
                this.$emit('control',identifier,value)
            },
            getRate(index){
                switch (index) {
                    case 2:
                    case 4:
                    case 7:
                        return this.model.rate
                    default:
                        return 1
                }
            }
        }
    }
</script>

<style lang="less" scoped>
.adjust-item{
    display: flex;
    flex-wrap: wrap;
    float: left;
    border: 1px solid #DDD;
    padding: 10px;

    .title{
        width: 100%;
        text-align: center;
        padding: 12px;
        font-size: 18px;
        font-weight: 500;
    }

    .grid{
        width: 33.3%;
        padding: 10px;
        text-align: center;
    }

    .grid-disable{
        opacity: 0.5;
    }

    .btn{
        width: 100px;
        text-align: center;
        margin-bottom: 5px;
    }

    .status-value{
        font-weight: 500;
    }
}





</style>
